import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Breadcrumbs from "examples/Breadcrumbs";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import * as ChartUtils from "utils";
import logo from "assets/images/maxu-gray.png";
import { call } from "services/axiosService/core";
import { useMaterialUIController } from "context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Chart } from "chart.js";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ReactPlayer from "react-player";
import Loader from "pages/loader/Loader";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Antelope from "assets/images/insights/antelope.png";
import Bear from "assets/images/insights/bear.png";
import Beaver from "assets/images/insights/beaver.png";
import Bee from "assets/images/insights/bee.png";
import Bobcat from "assets/images/insights/bobcat.png";
import Buffalo from "assets/images/insights/buffalo.png";
import Cat from "assets/images/insights/cat.png";
import Chameleon from "assets/images/insights/chameleon.png";
import Cheetah from "assets/images/insights/cheetah.png";
import Deer from "assets/images/insights/deer.png";
import Dolphin from "assets/images/insights/dolphin.png";
import Eagle from "assets/images/insights/eagle.png";
import Elephant from "assets/images/insights/elephant.png";
import Fox from "assets/images/insights/fox.png";
import GermanShepherd from "assets/images/insights/german-shepherd.png";
import Giraffe from "assets/images/insights/giraffe.png";
import GoldenRetriever from "assets/images/insights/golden-retriever.png";
import Hawk from "assets/images/insights/hawk.png";
import Kangaroo from "assets/images/insights/kangaroo.png";
import Koala from "assets/images/insights/koala.png";
import Leopard from "assets/images/insights/leopard.png";
import Lion from "assets/images/insights/lion.png";
import Meerkat from "assets/images/insights/meerkat.png";
import Moose from "assets/images/insights/moose.png";
import Mustang from "assets/images/insights/mustang.png";
import Orca from "assets/images/insights/orca.png";
import Otter from "assets/images/insights/otter.png";
import Owl from "assets/images/insights/owl.png";
import Panda from "assets/images/insights/panda.png";
import Panther from "assets/images/insights/panther.png";
import Peacock from "assets/images/insights/peacock.png";
import Penguin from "assets/images/insights/penguin.png";
import Puppy from "assets/images/insights/puppy.png";
import Rabbit from "assets/images/insights/rabbit.png";
import Rhino from "assets/images/insights/rhino.png";
import Shark from "assets/images/insights/shark.png";
import Sheep from "assets/images/insights/sheep.png";
import Squirrel from "assets/images/insights/squirrel.png";
import Tiger from "assets/images/insights/tiger.png";
import Turtle from "assets/images/insights/turtle.png";
import Wolf from "assets/images/insights/wolf.png";
import Zebra from "assets/images/insights/zebra.png";

import DefaultImage from "assets/images/insights/defaultInsight.png";
import { CircularProgress } from "@mui/material";
import MaxuLogo from "assets/images/nav-logo.png";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";

const DATA_COUNT_INNER = 5;
const NUMBER_CFG_INNER = { count: DATA_COUNT_INNER, min: 100, max: 100 };
const DATA_COUNT_OUTER = 15;
const NUMBER_CFG_OUTER = { count: DATA_COUNT_OUTER, min: 100, max: 100 };

const Animals = {
  Antelope,
  Bear,
  Beaver,
  Bee,
  Bobcat,
  Buffalo,
  Cat,
  Chameleon,
  Cheetah,
  Deer,
  Dolphin,
  Eagle,
  Elephant,
  Fox,
  GermanShepherd,
  Giraffe,
  GoldenRetriever,
  Hawk,
  Kangaroo,
  Koala,
  Leopard,
  Lion,
  Meerkat,
  Moose,
  Mustang,
  Orca,
  Otter,
  Owl,
  Panda,
  Panther,
  Peacock,
  Penguin,
  Puppy,
  Rabbit,
  Rhino,
  Shark,
  Sheep,
  Squirrel,
  Tiger,
  Turtle,
  Wolf,
  Zebra,
};

export function Insight() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { profile } = controller;
  const { id: assessment_id } = useParams();
  const { user_id: userId } = useParams();
  // const user_id = profile.id;
  const [insight, setInsight] = useState(null);
  const [insightMessage, setInsightMessage] = useState(null);
  const [insightName, setInsightName] = useState();
  const matches = useMediaQuery("(max-width:420px)");
  const [insightScoreKey, setInsightScoreKey] = useState([]);
  const [insightScoreValue, setInsightScoreValue] = useState([]);
  const [insightScoreValueOuter, setInsightScoreValueOuter] = useState([]);
  const [inSightContentLinks, setInsightContentLinks] = useState(null);
  const [insightBackground, setInsightBackground] = useState([]);
  const [animalName, setAnimalName] = useState(null);
  const [animalURL, setAnimalURL] = useState(null);
  const [animalThumbnail, setAnimalThumbnail] = useState(null);
  const [showMoreInsightsButton, setShowMoreInsightsButton] = useState(true);
  // const [showMoreContentsButton, setShowMoreContentsButton] = useState(true);
  const [insightSummary, setInsightSummary] = useState("");
  const [insightRecommendations, setInsightRecommendations] = useState([]);
  const [topContent, setTopContent] = useState([]);
  const [allContentLinks, setAllContentLinks] = useState([]);
  const [contentFilter, setContentFilter] = useState("allContentLinks");
  const [learnerName, setLearnerName] = useState("");
  const [loader, setLoader] = useState(true);
  const [content, setContent] = useState({});
  const [playContent, setPlayContent] = useState(false);
  const [userMetricsData, setUserMetricsData] = useState(null);
  const [likedContent, setLikedContent] = useState([]);
  const [dislikedContent, setDislikedContent] = useState([]);
  const [savedContent, setSavedContent] = useState([]);
  const [viewedContent, setViewedContent] = useState([]);
  const [recommendedContent, setRecommendedContent] = useState([]);
  const [shareContent, setShareContent] = useState(false);
  const [shareContentTitle, setShareContentTitle] = useState("");
  const [learnerMetricsData, setLearnerMetricData] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [assessmentType, setAssessmentType] = useState("NEO");
  const [hasGamePlan, setHasGamePlan] = useState(false);

  Chart.register(ChartDataLabels);
  let pieValue;
  const data = {
    labels: insightScoreKey,
    datasets: [
      {
        label: "Dataset Outer",
        scores: insightScoreValueOuter,
        data: ChartUtils.numbers(NUMBER_CFG_OUTER),
        backgroundColor: Object.values(ChartUtils.CHART_COLORS),
        borderWidth: 10,
        weight: matches ? 3 : 1,
        backgroundColor: [
          "#747577",
          "#E65472",
          "#747577",
          "#ED9243",
          "#747577",
          "#747577",
          "#F4C658",
          "#747577",
          "#747577",
          "#747577",
          "#747577",
          "#5DB2B2",
          "#478DDF",
          "#747577",
          "#747577",
        ],

        backgroundColor: insightBackground,
      },
      {
        label: "Dataset Inner",
        data: insightScoreValue,
        // data: ChartUtils.numbers(NUMBER_CFG_INNER),
        backgroundColor: Object.values(ChartUtils.CHART_COLORS),
        borderWidth: 10,
        weight: 5,
        backgroundColors: ["#E65472", "#ED9243", "#F4C658", "#5DB2B2", "#478DDF"],
      },
    ],
  };

  useEffect(() => {
    if (window.pendo) {
      window.pendo.track("View Insights Screen");
    }
  }, [window.pendo]);

  useEffect(() => {
    loadInsights();
    loadUserContentMetrics();
    loadLearnerContentMetrics();
    getUserGamePlan();
  }, []);

  // using the pendo guide using the api. for the future reference
  // useEffect(() => {
  //   OUR_PENDO_API_KEY = 'API Key for pendo'
  //   guideId = "your guide id"
  //   // Initialize Pendo SDK
  //   // window.pendo.initialize({ apiKey: 'YOUR_PENDO_API_KEY' });

  //   // Function to trigger Pendo guide
  //   const launchPendoGuide = () => {
  //     // Replace GUIDE_ID with your actual Pendo guide ID
  //     window.pendo.showGuideById(guideId);
  //   };

  //   // Example: Trigger the Pendo guide after 5 seconds
  //   const timeoutId = setTimeout(() => {
  //     launchPendoGuide();
  //   }, 1500);

  //   // Clean up the timeout
  //   return () => clearTimeout(timeoutId);
  // }, []);

  const loadInsights = () => {
    if (userId && assessment_id) {
      setLoader(true);
      call(`users/${userId}/assessments/${assessment_id}/insights`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            setLoader(false);
            const resData = res.data;
            console.log("insights data", res.data);
            const type = resData?.assessment_type || "NEO";
            setInsight(resData.insights.insight_details);
            setAssessmentType(type);
            setTopContent(
              resData.insights.top_content.sort((a, b) =>
                parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
              )
            );
            setAnimalName(resData.insights.animal);
            if (resData.insights.animal_url) {
              setAnimalThumbnail(resData.insights.animal_url);
            } else {
              setAnimalThumbnail(
                Animals[resData.insights.animal.split(" ").join("")] || DefaultImage
              );
            }
            setInsightSummary(resData.insights.summary);
            setLearnerName(resData.insights.learner_name);
            setInsightRecommendations(resData.insights.recommendations);
            const scores = Object.keys(resData.scores);
            setInsightScoreKey(scores);
            setInsightScoreValue([100, 100, 100, 100, 100]);
            if (type == "NEO") {
              const temp = [];
              Object.values(resData.scores).map((item) => {
                temp.push(item);
                temp.push(item);
                temp.push(item);
              });
              setInsightScoreValueOuter(temp);

              const background = [];
              const bgcolor = {};
              bgcolor[scores[0]] = "#E65472";
              bgcolor[scores[1]] = "#ED9243";
              bgcolor[scores[2]] = "#F4C658";
              bgcolor[scores[3]] = "#5DB2B2";
              bgcolor[scores[4]] = "#478DDF";

              var scoresObj = new Map(Object.entries(resData.scores));
              scoresObj.forEach((item, key) => {
                if (item < 45) {
                  background.push(bgcolor[key]);
                  background.push("#747577");
                  background.push("#747577");
                } else if (item < 56) {
                  background.push("#747577");
                  background.push(bgcolor[key]);
                  background.push("#747577");
                } else {
                  background.push("#747577");
                  background.push("#747577");
                  background.push(bgcolor[key]);
                }
              });
              setInsightBackground(background);
            }
          } else if (res.status == 440) {
            setSessionExpired(true);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    }
  };

  const getUserGamePlan = () => {
    call(`users/${profile.id}/gameplan`, "GET", {})
      .then((response) => {
        if (response.status === 200) {
          setHasGamePlan(true);
        } else if (response.status === 404) {
          setHasGamePlan(false);
        } else if (response.status === 440) {
          setSessionExpired(true);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
      });
  };

  const loadUserContentMetrics = () => {
    if (profile.id) {
      console.log("load user content metrics");
      call(`users/${profile.id}/contentmetrics`, "GET", {})
        .then((res) => {
          console.log("metrics", res.data);
          if (res.status == 200) {
            let data = res.data;
            setUserMetricsData(data);
            setLikedContent([...data.likedcontent]);
            setDislikedContent([...data.dislikedcontent]);
            setViewedContent([...data.viewedcontent]);
            setSavedContent([...data.savedcontent]);
            setRecommendedContent([...data.recommendedcontent]);
          } else if (res.status == 440) {
            setLoader(false);
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    }
  };

  const loadLearnerContentMetrics = () => {
    if (userId) {
      console.log("load Learner content metrics");
      call(`users/${userId}/contentmetrics`, "GET", {})
        .then((res) => {
          // console.log("metrics", res.data);
          if (res.status == 200) {
            let data = res.data;
            setLearnerMetricData(data);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    }
  };

  const pieSliceHAndler = (evt, activeEls, chart) => {
    if (!activeEls || activeEls.length === 0 || !activeEls[0]?.datasetIndex) {
      console.warn("No active elements or invalid structure", activeEls);
      return;
    }
    pieValue = chart.data.labels[activeEls[0].index];

    if (!pieValue) {
      console.warn("No valid pieValue for the active element", activeEls[0]);
      return;
    }
    if (pieValue === "Openness") {
      setInsightMessage(insight.Openness.messages);
      // setInsightContentLinks(
      //   insight.Openness.content_links.sort((a, b) =>
      //     parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
      //   )
      // );
      setContentFilter("Openness");
      setInsightName(t("insights.openness"));
    } else if (pieValue === "Conscientiousness") {
      setInsightMessage(insight.Conscientiousness.messages);
      // setInsightContentLinks(
      //   insight.Conscientiousness.content_links.sort((a, b) =>
      //     parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
      //   )
      // );
      setContentFilter("Conscientiousness");
      setInsightName(t("insights.conscientiousness"));
    } else if (pieValue === "Extraversion") {
      setInsightMessage(insight.Extraversion.messages);
      // setInsightContentLinks(
      //   insight.Extraversion.content_links.sort((a, b) =>
      //     parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
      //   )
      // );
      setContentFilter("Extraversion");
      setInsightName(t("insights.extraversion"));
    } else if (pieValue === "Agreeableness") {
      setInsightMessage(insight.Agreeableness.messages);
      // setInsightContentLinks(
      //   insight.Agreeableness.content_links.sort((a, b) =>
      //     parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
      //   )
      // );
      setContentFilter("Agreeableness");
      setInsightName(t("insights.agreeableness"));
    } else if (pieValue === "Temperament") {
      setInsightMessage(insight.Temperament.messages);
      // setInsightContentLinks(
      //   insight.Temperament.content_links.sort((a, b) =>
      //     parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
      //   )
      // );
      setContentFilter("Temperament");
      setInsightName(t("insights.temperament"));
    }
  };

  const showMoreInsightsHandler = () => {
    setShowMoreInsightsButton(false);
    setInsightContentLinks(allContentLinks);
    setContentFilter("allContentLinks");
  };

  const hideInsightsHandler = () => {
    setShowMoreInsightsButton(true);
    setInsightMessage(null);
    // setInsightContentLinks(null);
    setInsightName(null);
    // setShowMoreContentsButton(true);
  };

  const likeHandler = (title) => {
    let eventkey = { event: "likecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentlikedContent.push(title);
    // if content in disliked array remove it from there
    if (currentdislikedContent.includes(title)) {
      const index = currentdislikedContent.indexOf(title);
      currentdislikedContent.splice(index, 1);
      eventkey = { event: "likecontent-removedislike", value: title };
    }
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;

    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const dislikeHandler = (title) => {
    let eventkey = { event: "dislikecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentdislikedContent.push(title);
    // if content in liked array remove it from there
    if (currentlikedContent.includes(title)) {
      const index = currentlikedContent.indexOf(title);
      currentlikedContent.splice(index, 1);
      eventkey = { event: "dislikecontent-removelike", value: title };
    }
    // console.log("currentlikedContent", currentlikedContent);
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        // console.log("response is", res);
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeLikeHandler = (title) => {
    let eventkey = { event: "removelikecontent", value: title };

    var currentlikedContent = [...likedContent];
    const index = currentlikedContent.indexOf(title);
    currentlikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeDislikeHandler = (title) => {
    let eventkey = { event: "removedislikecontent", value: title };
    var currentdislikedContent = [...dislikedContent];
    const index = currentdislikedContent.indexOf(title);
    currentdislikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const saveHandler = (title) => {
    let eventkey = { event: "savedcontent", value: title };
    let currentSavedContent = [...savedContent];
    let metricData = userMetricsData;
    if (currentSavedContent.includes(title)) {
      const index = currentSavedContent.indexOf(title);
      currentSavedContent.splice(index, 1);
      eventkey = { event: "removeSaved", value: title };
    } else {
      currentSavedContent.push(title);
    }
    metricData["savedcontent"] = [...currentSavedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setSavedContent([...currentSavedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const shareHandler = (title) => {
    setShareContentTitle(title);
    setShareContent(true);
  };

  const getContentLinkHandler = (item) => {
    call(`content/${item.title}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setPlayContent(true);
          setContent(res.data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log("error is", err);
        Sentry.captureException(err);
      });
  };

  const closeContentPlayer = () => {
    setPlayContent(false);
  };

  const closeShareContentHandler = () => {
    setShareContent(false);
  };

  return (
    <DashboardLayout>
      {loader ? (
        <Dialog open={loader} fullScreen>
          <DialogContent>
            <Loader loaderText={t("loaderText.loadingInsights")} />
          </DialogContent>
        </Dialog>
      ) : (
        <>
          <DashboardNavbar
            breadcrumbs={
              <Breadcrumbs
                icon="home"
                title={t("pageTitles.insights")}
                route={["Insights"]}
                light={false}
              />
            }
          />
          <MDBox my={3}>
            <Grid container spacing={1} mt={0} mb={3}>
              <Grid item xs={12} md={12} xl={12} flex={1}>
                <Card style={{ height: "100%" }}>
                  {learnerName && (
                    <MDBox
                      pt={2}
                      px={2}
                      lineHeight={1.25}
                      // display={{ xs: "block", md: "none" }}
                      textAlign="center"
                    >
                      <MDBox mb={1}>
                        <MDTypography variant="h3" fontWeight="medium" alignItems={"center"}>
                          {t("insights.title", { learnerName: learnerName })}
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1}>
                        <MDTypography variant="h4" color="text" alignItems={"center"}>
                          {t("insights.animal", { animalName: animalName })}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  )}
                </Card>
              </Grid>
            </Grid>
            <Card>
              <Grid container spacing={1} mt={0} mb={3}>
                <Grid item xs={12} lg={6} xl={6}>
                  <MDBox
                    backgroundColor={"white"}
                    variant="gradient"
                    display="flex"
                    justifyContent="center"
                    alignItems="flex-start"
                    color="white"
                    width="100%"
                    height="100%"
                    maxWidth="520"
                    maxHeight="520"
                  >
                    {animalThumbnail ? (
                      <MDBox
                        mb={2}
                        component="img"
                        src={animalThumbnail}
                        alt={t("imageAlts.maxuLogo")}
                        width="80%"
                      />
                    ) : (
                      <Icon fontSize="large">insights</Icon>
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={12} lg={6} xl={6}>
                  <MDBox display="flex" flex={1} flexDirection="column" height="100%" weight="100%">
                    <MDBox mb={1} ml={2} mr={2}>
                      <MDTypography variant="body" fontSize={20} fontWeight="medium">
                        {insightSummary}
                      </MDTypography>
                    </MDBox>
                    <MDBox px={2} mx={2}>
                      <MDBox
                        component="ul"
                        display="flex"
                        flexDirection="column"
                        p={0}
                        m={0}
                        sx={{ listStyle: "none" }}
                      >
                        {insightRecommendations.map((mess, index) => {
                          return <Transaction index={index} message={mess} />;
                        })}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox py={2} pr={4} pl={4} mb={-4}>
                {showMoreInsightsButton && assessmentType == "NEO" && (
                  <MDButton
                    onClick={showMoreInsightsHandler}
                    style={{ width: "100%" }}
                    color="primary"
                  >
                    {t("insights.showInsightReport")}
                  </MDButton>
                )}
              </MDBox>
            </Card>

            {!showMoreInsightsButton && (
              <Grid container spacing={3} mt={2}>
                {/* chart */}
                <Grid item xs={12} md={6} lg={6} xl={5} flex={1}>
                  <Card style={{ height: "100%" }}>
                    <MDBox
                      style={{ height: "100%" }}
                      bgColor="white"
                      variant="gradient"
                      borderRadius="xl"
                      sx={{ background: ({ palette: { background } }) => false && background.card }}
                    >
                      <MDBox py={2} pr={2} pl={4} style={{ height: "100%" }}>
                        <MDBox display="flex" px={1} pt={1}>
                          <MDBox
                            width="4rem"
                            height="4rem"
                            bgColor={"primary"}
                            variant="gradient"
                            coloredShadow={"primary"}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            mt={-5}
                            mr={2}
                          >
                            <Icon fontSize="large">psychology</Icon>
                          </MDBox>
                          <MDBox mt={-2}>
                            <MDTypography variant="h6">{t("insights.insightChart")}</MDTypography>
                          </MDBox>
                        </MDBox>
                        <MDBox pt={3} pb={2} px={2}>
                          <MDBox mb={2} style={{ position: "relative" }}>
                            <Doughnut
                              data={data}
                              options={{
                                animation: {
                                  duration: 0,
                                },
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                  tooltip: {
                                    xAlign: "center",
                                    yAlign: "bottom",
                                    backgroundColor: "#ffffff",
                                    bodyColor: "#111111",
                                    borderWidth: 1,
                                    borderColor: "#111111",
                                    callbacks: {
                                      // To change label in tooltip
                                      label: (data) => {
                                        if (data.datasetIndex == 1) {
                                          const labelKey = data.label
                                            ? data.label.toLowerCase()
                                            : "default";
                                          return t(`insights.${labelKey}`);
                                        } else {
                                          let val = parseInt(data.dataset.scores[data.dataIndex]);
                                          if (val < 45) {
                                            return t(`insights.low`);
                                          } else if (val < 56) {
                                            return t(`insights.medium`);
                                          } else {
                                            return t(`insights.high`);
                                          }
                                        }
                                      },
                                    },
                                    filter: function (tooltipItem, data) {
                                      const { backgroundColor } = tooltipItem.dataset;
                                      if (backgroundColor[tooltipItem.dataIndex] === "#747577") {
                                        return false;
                                      } else {
                                        return true;
                                      }
                                    },
                                  },
                                  datalabels: {
                                    formatter: function (value, context) {
                                      const val = context.chart.data.labels[context.dataIndex];
                                      if (context.datasetIndex === 1) {
                                        if (typeof val == "string") {
                                          return val.slice(0, 1);
                                        }
                                      } else {
                                        return "";
                                      }
                                    },
                                    font: {
                                      weight: "bold",
                                      size: 16,
                                    },
                                    color: "white",
                                  },
                                },
                                onHover: (event, activeElements) => {
                                  if (activeElements?.length > 0) {
                                    event.native.target.style.cursor = "pointer";
                                  } else {
                                    event.native.target.style.cursor = "auto";
                                  }
                                },
                                onClick: (evt, activeEls, chart) =>
                                  pieSliceHAndler(evt, activeEls, chart),
                              }}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                position: "absolute",
                                top: "54%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <MDBox
                                component="img"
                                src={logo}
                                alt={t("imageAlts.maxuLogo")}
                                width="65%"
                              />
                            </div>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={7}>
                  <Card style={{ height: "100%" }}>
                    <MDBox
                      bgColor="white"
                      variant="gradient"
                      borderRadius="xl"
                      style={{ height: "100%" }}
                      sx={{ background: ({ palette: { background } }) => false && background.card }}
                    >
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        pt={2}
                        pb={10}
                        pr={2}
                        pl={4}
                        style={{ height: "100%" }}
                      >
                        <MDTypography variant="h6">{insightName}</MDTypography>
                        <MDBox pt={3} pb={2} px={2}>
                          <MDBox
                            component="ul"
                            display="flex"
                            flexDirection="column"
                            p={0}
                            m={0}
                            sx={{ listStyle: "none" }}
                          >
                            {insightMessage ? (
                              insightMessage.map((mess, index) => {
                                return <Transaction index={index} message={mess} />;
                              })
                            ) : (
                              <MDTypography variant="h6" fontWeight="medium" textAlign="center">
                                {t("insights.insightMessage")}
                              </MDTypography>
                            )}
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} xl={12}>
                  <MDBox py={2} pr={4} pl={4} mt={-8}>
                    {!showMoreInsightsButton && (
                      <MDButton
                        onClick={hideInsightsHandler}
                        style={{ width: "100%" }}
                        color="primary"
                      >
                        {t("insights.hideInsightReport")}
                      </MDButton>
                    )}
                  </MDBox>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1} mt={2}>
              {topContent && (
                <Grid item xs={12} md={12} flex={1}>
                  <Card style={{ height: "100%" }}>
                    <MDBox
                      style={{ height: "100%" }}
                      bgColor="white"
                      variant="gradient"
                      borderRadius="xl"
                      sx={{ background: ({ palette: { background } }) => false && background.card }}
                    >
                      <MDBox py={2} pr={2} pl={4} style={{ height: "100%" }}>
                        <MDBox pt={2} px={2} lineHeight={1.25}>
                          <MDTypography variant="h6" fontWeight="medium">
                            {t("insights.topContentTitle")}
                          </MDTypography>
                        </MDBox>
                        <MDBox p={2}>
                          <Grid container spacing={6}>
                            {topContent &&
                              topContent.map((item, index) => {
                                let icon = "movie";
                                let content_type = item.content_type.toLowerCase();
                                if (content_type == "video") {
                                  icon = "movie";
                                } else if (content_type == "infographic") {
                                  icon = "leaderboard";
                                } else if (content_type == "article") {
                                  icon = "article";
                                } else if (content_type == "worksheet") {
                                  icon = "note";
                                }
                                return (
                                  <Grid item xs={12} md={4}>
                                    <DefaultProjectCard
                                      item={item}
                                      icon={icon}
                                      type={item.content_type}
                                      title={item.title}
                                      content_url={item.content_url}
                                      image={item.thumbnail_url || MaxuLogo}
                                      getContentLinkHandler={getContentLinkHandler}
                                      shareable={userId != profile.id}
                                      liked={likedContent.includes(item.title)}
                                      disliked={dislikedContent.includes(item.title)}
                                      saved={savedContent.includes(item.title)}
                                      viewed={viewedContent.includes(item.title)}
                                      likeHandler={likeHandler}
                                      dislikeHandler={dislikeHandler}
                                      removeLikeHandler={removeLikeHandler}
                                      removeDislikeHandler={removeDislikeHandler}
                                      saveHandler={saveHandler}
                                      shareHandler={shareHandler}
                                      action={{
                                        type: "external",
                                        route: item.content_url,
                                        color: "info",
                                        label: t("buttonTitles.view"),
                                      }}
                                    />
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              )}
               {(userId == profile.id && hasGamePlan) ? (
                <Grid item xs={12} xl={12}>
                  <MDBox py={2} pr={4} pl={4} mt={-6}>
                    <MDButton
                      onClick={() =>
                        navigate("/mygameplan")
                      }
                      style={{ width: "100%" }}
                      color="primary"
                    >
                      {t("insights.viewGamePlan")}
                    </MDButton>
                  </MDBox>
                </Grid>
              ) : (
                <Grid item xs={12} xl={12}>
                  <MDBox py={2} pr={4} pl={4} mt={-6}>
                    <MDButton
                      onClick={() =>
                        navigate("/contentlibrary", {
                          state: { learnerId: userId, assessmentId: assessment_id },
                        })
                      }
                      style={{ width: "100%" }}
                      color="primary"
                    >
                      {t("insights.showMoreContent")}
                    </MDButton>
                  </MDBox>
                </Grid>
              )}
            </Grid>
          </MDBox>
        </>
      )}
      {playContent && (
        <ContentPlayerDialog
          playContent={playContent}
          closeContentPlayer={closeContentPlayer}
          content={content}
          userMetricsData={userMetricsData}
          setUserMetricData={setUserMetricsData}
          setViewedContent={setViewedContent}
          userId={profile.id}
          setSessionExpired={setSessionExpired}
          t={t}
        />
      )}
      {shareContent && (
        <ShareContentDialog
          shareContent={shareContent}
          setShareContent={setShareContent}
          closeShareContentHandler={closeShareContentHandler}
          shareContentTitle={shareContentTitle}
          learnerMetricsData={learnerMetricsData}
          setLearnerMetricData={setLearnerMetricData}
          learnerId={userId}
          setSessionExpired={setSessionExpired}
          t={t}
        />
      )}
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
}

function Transaction({ index, message }) {
  return (
    <MDBox key={index} component="ul" py={1} pr={2} mb={1}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          <MDBox
            display="flex"
            flexDirection="column"
            component="ul"
            style={{ listStyleType: "disc" }}
          >
            <MDTypography
              component="li"
              variant="caption"
              color="text"
              fontSize={20}
              fontWeight={"light"}
            >
              {message}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

const ContentPlayerDialog = ({
  playContent,
  closeContentPlayer,
  content,
  userMetricsData,
  setUserMetricData,
  setViewedContent,
  userId,
  setSessionExpired,
  t,
}) => {
  let src = content.content_url;
  let type = content.content_type.toLowerCase();
  let title = content.title;

  useEffect(() => {
    if (type == "video") {
      updateMetrics();
    }
  }, []);

  const updateMetrics = () => {
    console.log("updating metrics");
    let viewedcontent = userMetricsData["viewedcontent"];
    if (viewedcontent.includes(title)) {
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    } else {
      userMetricsData["viewedcontent"] = [...viewedcontent, title];
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    }
    call(`users/${userId}/contentmetrics`, "PUT", { body: userMetricsData })
      .then((res) => {
        // console.log("Res is ", res);
        if (res.status == 200) {
          setUserMetricData(userMetricsData);
          setViewedContent([...userMetricsData.viewedcontent]);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={playContent} onClose={closeContentPlayer} fullWidth={true} maxWidth={"md"}>
      <CancelPresentationIcon
        fontSize="large"
        onClick={closeContentPlayer}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        {type == "video" ? (
          <ReactPlayer url={src} playing={true} controls={true} width="100%" />
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignSelf="end"
            alignItems="center"
            onClick={closeContentPlayer}
          >
            <MDButton
              onClick={updateMetrics}
              component={"a"}
              href={src}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="large"
              color={"info"}
            >
              {t("playContent.openInNewTab")}
            </MDButton>
          </MDBox>
          // <iframe src={src}  width="100%" height="300">
          // </iframe>
        )}
      </DialogContent>
    </Dialog>
  );
};

const ShareContentDialog = ({
  shareContent,
  setShareContent,
  closeShareContentHandler,
  shareContentTitle,
  learnerMetricsData,
  setLearnerMetricData,
  learnerId,
  setSessionExpired,
  t,
}) => {
  const [learnersRecommendations, setLearnerRecommendations] = useState(
    learnerMetricsData["recommendedcontent"]
  );
  const [recommend, setRecommend] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // console.log("learnersRecommendations", learnersRecommendations);
    // console.log("shareContentTitle", shareContentTitle);
    if (learnersRecommendations.includes(shareContentTitle)) {
      setRecommend(false);
    }
  }, []);

  const recommendHandler = () => {
    setLoading(true);
    learnerMetricsData["recommendedcontent"] = [...learnersRecommendations, shareContentTitle];
    learnerMetricsData["eventkey"] = { event: "recommendcontent", value: shareContentTitle };
    // console.log(" learnerMetricsData", learnerMetricsData);
    call(`users/${learnerId}/contentmetrics`, "PUT", { body: learnerMetricsData })
      .then((res) => {
        // console.log("Res is ", res);
        if (res.status == 200) {
          setLearnerMetricData(learnerMetricsData);
          setShareContent(false);
        } else if (res.status == 440) {
          setShareContent(false);
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setLoading(false);
      });
  };

  return (
    <Dialog open={shareContent} onClose={closeShareContentHandler} fullWidth={true} maxWidth={"md"}>
      <CancelPresentationIcon
        fontSize="large"
        onClick={closeShareContentHandler}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />

      <DialogTitle>{t("shareContent.title")}</DialogTitle>
      {recommend ? (
        <DialogContent>
          <MDTypography variant="h6" fontWeight="high">
            {t("shareContent.recommend", { title: shareContentTitle })}
          </MDTypography>
        </DialogContent>
      ) : (
        <DialogContent>
          <MDTypography variant="h6" fontWeight="high">
            {t("shareContent.shared")}
          </MDTypography>
        </DialogContent>
      )}

      <DialogActions>
        {recommend && (
          <MDButton onClick={recommendHandler} style={{ color: "white", background: "#0AB2D6" }}>
            {loading ? (
              <CircularProgress size={15} color={"white"} />
            ) : (
              t("shareContent.buttonTitles.recommend")
            )}
          </MDButton>
        )}

        <MDButton
          onClick={closeShareContentHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          {t("shareContent.buttonTitles.close")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};
