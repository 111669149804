import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import { call } from "services/axiosService/core";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import ReactPlayer from "react-player";
import MaxuLogo from "assets/images/nav-logo.png";
import MDBox from "components/MDBox";
import Loader from "pages/loader/Loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

const MyGamePlan = () => {
  const [controller] = useMaterialUIController();
  const { profile, persona } = controller;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [gamePlan, setGamePlan] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [userMetricsData, setUserMetricsData] = useState(null);
  const [likedContent, setLikedContent] = useState([]);
  const [dislikedContent, setDislikedContent] = useState([]);
  const [savedContent, setSavedContent] = useState([]);
  const [viewedContent, setViewedContent] = useState([]);
  const [recommendedContent, setRecommendedContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noContent, setNoContent] = useState(false);
  const [content, setContent] = useState({});
  const [playContent, setPlayContent] = useState(false);
  const [hasCompletedAll, setHasCompletedAll] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);

  const congratulationAnimation = {
    hidden: { opacity: 1, scale: 1, y: "-50vh" },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 3, // Now the duration will be respected
        ease: "easeInOut", // Smooth in & out effect
      },
    },
  };

  useEffect(() => {
    getUserGamePlan();
    loadUserContentMetrics();
  }, [profile]);

  const getUserGamePlan = () => {
    call(`users/${profile.id}/gameplan`, "GET", {})
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.game_plan;
          const latest_plan = response.data.game_plan[0];
          setGamePlan(response.data.game_plan);
          setHasCompletedAll(latest_plan.content.length == latest_plan.watched_content.length);
          setLoading(false);
        } else if (response.status === 404) {
          setGamePlan([]);
          setNoContent(true);
          setLoading(false);
        } else if (response.status === 440) {
          setSessionExpired(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        setLoading(false);
        console.log(error);
      });
  };

  const loadUserContentMetrics = () => {
    call(`users/${profile.id}/contentmetrics`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setUserMetricsData(data);
          setLikedContent([...data.likedcontent]);
          setDislikedContent([...data.dislikedcontent]);
          setViewedContent([...data.viewedcontent]);
          setSavedContent([...data.savedcontent]);
          setRecommendedContent([...data.recommendedcontent]);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const likeHandler = (title) => {
    let eventkey = { event: "likecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentlikedContent.push(title);
    // if content in disliked array remove it from there
    if (currentdislikedContent.includes(title)) {
      const index = currentdislikedContent.indexOf(title);
      currentdislikedContent.splice(index, 1);
      eventkey = { event: "likecontent-removedislike", value: title };
    }
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;

    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const dislikeHandler = (title) => {
    let eventkey = { event: "dislikecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentdislikedContent.push(title);
    // if content in liked array remove it from there
    if (currentlikedContent.includes(title)) {
      const index = currentlikedContent.indexOf(title);
      currentlikedContent.splice(index, 1);
      eventkey = { event: "dislikecontent-removelike", value: title };
    }
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeLikeHandler = (title) => {
    let eventkey = { event: "removelikecontent", value: title };

    var currentlikedContent = [...likedContent];
    const index = currentlikedContent.indexOf(title);
    currentlikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeDislikeHandler = (title) => {
    let eventkey = { event: "removedislikecontent", value: title };
    var currentdislikedContent = [...dislikedContent];
    const index = currentdislikedContent.indexOf(title);
    currentdislikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const saveHandler = (title) => {
    let eventkey = { event: "savedcontent", value: title };
    let currentSavedContent = [...savedContent];
    let metricData = userMetricsData;
    if (currentSavedContent.includes(title)) {
      const index = currentSavedContent.indexOf(title);
      currentSavedContent.splice(index, 1);
      eventkey = { event: "removeSaved", value: title };
    } else {
      currentSavedContent.push(title);
    }
    metricData["savedcontent"] = [...currentSavedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setSavedContent([...currentSavedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  //   const shareHandler = (title) => {
  //     setShareContentTitle(title);
  //     setShareContent(true);
  //   };

  const getContentLinkHandler = (item) => {
    call(`content/${item.title}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setPlayContent(true);
          setContent(res.data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log("error is", err);
        Sentry.captureException(err);
      });
  };

  const closeContentPlayer = (congrats) => {
    setPlayContent(false);
    setShowCongratulations(congrats)
  };

  //   const closeShareContentHandler = () => {
  //     setShareContent(false);
  //   };

  return (
    <DashboardLayout>
      <DashboardNavbar title={t("pageTitles.myGamePlan")} />

      <Grid container spacing={3}>
        {gamePlan &&
          gamePlan.length > 0 &&
          (() => {
            const game_plan_item = gamePlan[0];
            if (game_plan_item.status === "active" && Array.isArray(game_plan_item.content)) {
              const totalItems = game_plan_item.content.length;
              const viewedItems = game_plan_item.watched_content.length;
              const progressPercentage =
                totalItems > 0 ? Math.round((viewedItems / totalItems) * 100) : 0;
              const gamePlanWeek = game_plan_item.week;
              return (
                <Grid item xs={12} sx={{ marginBottom: 5 }}>
                  <Card
                    sx={{
                      paddingTop: 3,
                      paddingX: 2,
                      marginTop: 2,
                      height: "100%",
                      backgroundColor: "#FAFAFA",
                      borderRadius: 10,
                      boxShadow: "2px 2px 9px #49a3f1",
                      border: "2px solid #49a3f1",
                    }}
                  >
                    <MDBox pb={2} px={2} lineHeight={1.25} alignItems="center">
                      <MDTypography
                        variant="h4"
                        fontWeight="bold"
                        color="#333"
                        sx={{
                          pb: 1,
                          mb: 3,
                          mt: 3,
                          textAlign: "center",
                        }}
                      >
                        {t("gamePlan.heading")}
                      </MDTypography>
                    </MDBox>

                    {/* Content Items */}
                    <Grid container spacing={3} className="mt-3" paddingX={2}>
                      {game_plan_item.content.map((item, contentIndex) => {
                        let icon = "movie";
                        let content_type = item.content_type.toLowerCase();

                        if (content_type === "video") icon = "movie";
                        else if (content_type === "infographic") icon = "leaderboard";
                        else if (content_type === "article") icon = "article";
                        else if (content_type === "worksheet") icon = "note";

                        return (
                          <Grid item xs={12} md={4} key={contentIndex}>
                            <DefaultProjectCard
                              item={item}
                              icon={icon}
                              type={item.content_type}
                              title={item.title}
                              content_url={item.content_url}
                              image={item.thumbnail_url || MaxuLogo}
                              getContentLinkHandler={getContentLinkHandler}
                              liked={likedContent.includes(item.title)}
                              disliked={dislikedContent.includes(item.title)}
                              saved={savedContent.includes(item.title)}
                              viewed={viewedContent.includes(item.title)}
                              likeHandler={likeHandler}
                              dislikeHandler={dislikeHandler}
                              removeLikeHandler={removeLikeHandler}
                              removeDislikeHandler={removeDislikeHandler}
                              saveHandler={saveHandler}
                              action={{
                                type: "external",
                                route: item.content_url,
                                color: "info",
                                label: t("buttonTitles.view"),
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Box display="flex" alignItems="center" gap={2} mt={4}>
                      <Box display="flex" gap={1} flexGrow={1}>
                        {game_plan_item.content.map((_, contentIndex) => (
                          <Box
                            key={contentIndex}
                            sx={{
                              width: "20px", // Adjust width as needed
                              height: "20px",
                              borderRadius: "4px",
                              backgroundColor:
                                contentIndex < game_plan_item.watched_content.length
                                  ? "#6DA34D"
                                  : "#c8e9f7",
                              flexGrow: 1,
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Typography
                      variant="body2"
                      textAlign={"center"}
                      sx={{ minWidth: "40px", fontWeight: "bold" }}
                    >
                      {progressPercentage}%
                    </Typography>
                  </Card>
                </Grid>
              );
            }
            return null;
          })()}
      </Grid>

      {showCongratulations && (
        <Grid item xs={12} sx={{ pb: 5, display: "flex", justifyContent: "center" }}>
          <motion.div initial="hidden" animate="visible" variants={congratulationAnimation}>
            <Card
              sx={{
                padding: 3,
                textAlign: "center",
                backgroundColor: "#E1F5FE",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MDTypography variant="h5" fontWeight="bold" color="primary">
                🎉 {t("gamePlan.congratulations")}! 🎉
              </MDTypography>
              <MDTypography variant="body1" color="textPrimary" mt={1}>
                {t("gamePlan.completedAllContentMessage")}
              </MDTypography>
            </Card>
          </motion.div>
        </Grid>
      )}

      {hasCompletedAll && (
        <Grid item xs={12}>
          <Card
            sx={{
              padding: 3,
              textAlign: "center",
              backgroundColor: "#FAFAFA",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <MDTypography variant="h6" fontWeight="bold">
              {t("gamePlan.lookingForMoreContent")}
            </MDTypography>
            <MDTypography variant="body2" color="textPrimary" mt={1}>
              {t("gamePlan.askMaxwell")}
            </MDTypography>

            <MDButton
              onClick={() => navigate("/dashboard", { state: { openBot: true } })}
              variant="contained"
              color="primary"
              sx={{ mt: 2, mx: 1 }}
            >
               
        {t("gamePlan.chatWithMaxwell")}
            </MDButton>

            <MDButton
              onClick={() => navigate("/contentlibrary")}
              variant="outlined"
              color="primary"
              sx={{ mt: 2, mx: 1 }}
            >
              {t("gamePlan.visitContentLibrary")}
            </MDButton>
          </Card>
        </Grid>
      )}

      {playContent && (
        <ContentPlayerDialog
          playContent={playContent}
          gamePlan={gamePlan}
          setGamePlan={setGamePlan}
          closeContentPlayer={closeContentPlayer}
          content={content}
          userMetricsData={userMetricsData}
          setUserMetricData={setUserMetricsData}
          setViewedContent={setViewedContent}
          setHasCompletedAll={setHasCompletedAll}
          userId={profile.id}
          setSessionExpired={setSessionExpired}
          t={t}
        />
      )}

      {noContent && (
        <MDBox mt={2.5}>
          <Card style={{ backgroundColor: "#49a3f1", padding: 20, opacity: 0.8 }}>
            <MDBox display="flex" flexDirection="column">
              <MDTypography
                textAlign="center"
                justifyContent="center"
                component="h3"
                fontWeight="medium"
                color="white"
                flexWrap="wrap"
              >
                {t("playList.noContentAvailable")}
              </MDTypography>
              <MDTypography
                textAlign="center"
                component="h3"
                variant="body2"
                fontWeight="medium"
                color="white"
                alignItems="center"
                flexWrap="wrap"
              >
                {t("playList.contactSupport")} {"  "}
                <a href="mailto:support@maxu.co"> support@maxu.co </a>
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      )}

      {loading && (
        <Dialog open={loading} fullScreen>
          <DialogContent>
            <Loader loaderText={t("loaderText.loadingGamePlan")} />
          </DialogContent>
        </Dialog>
      )}
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

export default MyGamePlan;

const ContentPlayerDialog = ({
  playContent,
  gamePlan,
  setGamePlan,
  closeContentPlayer,
  content,
  userMetricsData,
  setUserMetricData,
  setViewedContent,
  setHasCompletedAll,
  userId,
  setSessionExpired,
}) => {
  let src = content.content_url;
  let type = content.content_type.toLowerCase();
  let title = content.title;
  const [showCongrats, setShowCongrats] = useState(false)
  useEffect(() => {
    if (type == "video") {
      updateMetrics();
    }
  }, []);

  const updateMetrics = () => {
    let watched_content = gamePlan[0].watched_content;
    if (!watched_content.includes(title)) {
      gamePlan[0].watched_content = [...watched_content, title];
      call(`users/${userId}/gameplan`, "PUT", { body: gamePlan })
        .then((res) => {
          if (res.status == 200) {
            let watched_length = gamePlan[0].watched_content.length;
            let actual_length = gamePlan[0].content.length;
            if (watched_length == actual_length) {
              setShowCongrats(true);
              setHasCompletedAll(true);
            }
            setGamePlan(gamePlan);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
        });
    }

    let viewedcontent = userMetricsData["viewedcontent"];
    if (viewedcontent.includes(title)) {
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    } else {
      userMetricsData["viewedcontent"] = [...viewedcontent, title];
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    }
    call(`users/${userId}/contentmetrics`, "PUT", { body: userMetricsData })
      .then((res) => {
        if (res.status == 200) {
          setUserMetricData(userMetricsData);
          setViewedContent([...userMetricsData.viewedcontent]);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };
  return (
    <Dialog open={playContent} onClose={() => closeContentPlayer(showCongrats)} fullWidth={true} maxWidth={"md"}>
      <CancelPresentationIcon
        fontSize="large"
        onClick={() => closeContentPlayer(showCongrats)}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        {type == "video" ? (
          <ReactPlayer url={src} playing={true} controls={true} width="100%" />
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignSelf="end"
            alignItems="center"
            // onClick={()=>closeContentPlayer(showCongrats)}
          >
            <MDButton
              onClick={updateMetrics}
              component={"a"}
              href={src}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="large"
              color={"info"}
            >
              Open in new tab
            </MDButton>
          </MDBox>
        )}
      </DialogContent>
    </Dialog>
  );
};
