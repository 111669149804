import React from "react";
import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FormLabel from "@mui/material/FormLabel";
import logo from "assets/images/maxu-gray.png";
import { call } from "services/axiosService/core";
import { useMaterialUIController } from "context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import MaxuLogo from "assets/images/nav-logo.png";
import Loader from "pages/loader/Loader";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import ReactPlayer from "react-player";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";

const ContentLibrary = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { profile, persona } = controller;
  const [insight, setInsight] = useState(null);
  const matches = useMediaQuery("(max-width:420px)");
  const [inSightContentLinks, setInsightContentLinks] = useState(null);
  const [topContent, setTopContent] = useState([]);
  const [allContentLinks, setAllContentLinks] = useState([]);
  const [contentFilter, setContentFilter] = useState("allContentLinks");
  const [learnerName, setLearnerName] = useState("");
  const [learnersDetails, setLearnersDetails] = useState([]);
  const [allAssessments, setAllAssessments] = useState([]);
  const [val, setVal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noContent, setNoContent] = useState(false);
  const [learnerId, setLearnerId] = useState(location.state ? location.state.learnerId : null);
  const [assessmentId, setAssessmentId] = useState(
    location.state ? location.state.assessmentId : null
  );
  const [content, setContent] = useState({});
  const [playContent, setPlayContent] = useState(false);
  const [userMetricsData, setUserMetricsData] = useState(null);
  const [likedContent, setLikedContent] = useState([]);
  const [dislikedContent, setDislikedContent] = useState([]);
  const [savedContent, setSavedContent] = useState([]);
  const [viewedContent, setViewedContent] = useState([]);
  const [recommendedContent, setRecommendedContent] = useState([]);
  const [shareContent, setShareContent] = useState(false);
  const [shareContentTitle, setShareContentTitle] = useState("");
  const [learnerMetricsData, setLearnerMetricData] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [assessmentType, setAssessmentType] = useState("NEO");
  useEffect(() => {
    loadAllLearnersAssessments();
  }, [profile]);

  useEffect(() => {
    loadUserContents();
  }, [assessmentId]);

  useEffect(() => {
    loadUserContentMetrics();
  }, [profile]);

  useEffect(() => {
    loadLearnerContentMetrics();
  }, [learnerId]);

  const loadLearnerContentMetrics = () => {
    if (learnerId != profile.id) {
      call(`users/${learnerId}/contentmetrics`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            setLearnerMetricData(data);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    }
  };

  const loadUserContentMetrics = () => {
    if (profile.id) {
      call(`users/${profile.id}/contentmetrics`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            setUserMetricsData(data);
            setLikedContent([...data.likedcontent]);
            setDislikedContent([...data.dislikedcontent]);
            setViewedContent([...data.viewedcontent]);
            setSavedContent([...data.savedcontent]);
            setRecommendedContent([...data.recommendedcontent]);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    }
  };

  const loadAllLearnersAssessments = () => {
    if (profile.id) {
      call(`users/${profile.id}/content`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            if (data.length == 0) {
              setNoContent(true);
              setLoading(false);
            } else if (data.length == 1) {
              setLearnerId(data[0]["learnerId"]);
              if (data[0]["assessments"].length == 0) {
                setLoading(false);
                setLearnersDetails(data);
                setNoContent(true);
              } else {
                setLoading(false);
                setNoContent(false);
                setLearnersDetails(data);
                setAllAssessments(data[0]["assessments"]);
                assessmentId == null
                  ? setVal(data[0]["assessments"][0])
                  : setVal(
                      data[0]["assessments"].filter((row) => row.assessment_id == assessmentId)[0]
                    );
                assessmentId == null && setAssessmentId(data[0]["assessments"][0]["assessment_id"]);
              }
            } else {
              if (assessmentId == null) {
                setLoading(false);
                setLearnerId(data[0]["learnerId"]);
                if (data[0]["assessments"].length == 0) {
                  setAllAssessments(data[0]["assessments"]);
                  setLearnersDetails(data);
                  setNoContent(true);
                } else {
                  setAllAssessments(data[0]["assessments"]);
                  setAssessmentId(data[0]["assessments"][0]["assessment_id"]);
                  setVal(data[0]["assessments"][0]);
                  setLearnersDetails(data);
                }
              } else {
                const le = data.filter((row) => row.learnerId == learnerId);
                setAllAssessments(le[0]["assessments"]);
                const as = le[0]["assessments"].filter((row) => row.assessment_id == assessmentId);
                setVal(as[0]);
                setLearnersDetails(data);
              }
            }
          } else if (res.status == 440) {
            setSessionExpired(true);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          Sentry.captureException(err);
          console.log("error is", err);
        });
    }
  };

  const loadUserContents = () => {
    if (learnerId != null && assessmentId != null) {
      setLoading(true);
      call(`users/${learnerId}/assessments/${assessmentId}/contents`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            const resData = res.data;
            const type = resData.assessment_type;
            setAssessmentType(type);
            setTopContent(
              resData.insights.top_content.sort((a, b) =>
                parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
              )
            );
            let allLinks = [];
            if (type == "NEO") {
              setInsight(resData.insights.insight_details);
              allLinks = [
                ...resData.insights.insight_details.Agreeableness.content_links,
                ...resData.insights.insight_details.Conscientiousness.content_links,
                ...resData.insights.insight_details.Openness.content_links,
                ...resData.insights.insight_details.Extraversion.content_links,
                ...resData.insights.insight_details.Temperament.content_links,
              ];
            } else {
              setInsight(resData.insights.content);
              allLinks = [...resData.insights.content];
            }
            const ids = allLinks.map(({ title }) => title);
            const filteredLinks = allLinks.filter(
              ({ title }, index) => !ids.includes(title, index + 1)
            );
            filteredLinks.sort((a, b) => (parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1));
            setAllContentLinks(filteredLinks);
            setInsightContentLinks(filteredLinks);
            // setLearnerName(resData.insights.summary.split(" ")[0]);
            setLoading(false);
            setNoContent(false);
          } else if (res.status == 440) {
            setLoading(false);
            setSessionExpired(true);
          } else {
            setNoContent(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setNoContent(true);
          setLoading(false);
        });
    }
  };

  const defaultLearner = () => {
    if (learnerId == null) {
      return learnersDetails[0];
    } else {
      const le = learnersDetails.filter((row) => row.learnerId == learnerId);
      setLearnerName(le[0].learnerName.split(" ")[0]);
      return le[0];
    }
  };

  const learnerChangeHandler = (value) => {
    setLearnerName(value.learnerName.split(" ")[0]);
    setLearnerId(value.learnerId);
    setInsight(null);
    setAllAssessments([]);

    let user = learnersDetails.filter((row) => row.learnerId == value.learnerId);
    let assess = user[0]["assessments"];
    if (assess.length == 0) {
      setAllAssessments(assess);
      setAssessmentId(null);
      setVal(null);
      setNoContent(true);
    } else {
      setVal(assess[0]);
      setAssessmentId(assess[0]["assessment_id"]);
      setAllAssessments(assess);
    }
  };

  const likeHandler = (title) => {
    let eventkey = { event: "likecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentlikedContent.push(title);
    // if content in disliked array remove it from there
    if (currentdislikedContent.includes(title)) {
      const index = currentdislikedContent.indexOf(title);
      currentdislikedContent.splice(index, 1);
      eventkey = { event: "likecontent-removedislike", value: title };
    }
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;

    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const dislikeHandler = (title) => {
    let eventkey = { event: "dislikecontent", value: title };
    var currentlikedContent = [...likedContent];
    var currentdislikedContent = [...dislikedContent];
    currentdislikedContent.push(title);
    // if content in liked array remove it from there
    if (currentlikedContent.includes(title)) {
      const index = currentlikedContent.indexOf(title);
      currentlikedContent.splice(index, 1);
      eventkey = { event: "dislikecontent-removelike", value: title };
    }
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeLikeHandler = (title) => {
    let eventkey = { event: "removelikecontent", value: title };

    var currentlikedContent = [...likedContent];
    const index = currentlikedContent.indexOf(title);
    currentlikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["likedcontent"] = [...currentlikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setLikedContent([...currentlikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const removeDislikeHandler = (title) => {
    let eventkey = { event: "removedislikecontent", value: title };
    var currentdislikedContent = [...dislikedContent];
    const index = currentdislikedContent.indexOf(title);
    currentdislikedContent.splice(index, 1);
    let metricData = userMetricsData;
    metricData["dislikedcontent"] = [...currentdislikedContent];
    metricData["eventkey"] = eventkey;
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setDislikedContent([...currentdislikedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const saveHandler = (title) => {
    let eventkey = { event: "savedcontent", value: title };
    let currentSavedContent = [...savedContent];
    let metricData = userMetricsData;
    if (currentSavedContent.includes(title)) {
      const index = currentSavedContent.indexOf(title);
      currentSavedContent.splice(index, 1);
      eventkey = { event: "removeSaved", value: title };
    } else {
      currentSavedContent.push(title);
    }
    metricData["eventkey"] = eventkey;
    metricData["savedcontent"] = [...currentSavedContent];
    call(`users/${profile.id}/contentmetrics`, "PUT", { body: metricData })
      .then((res) => {
        if (res.status == 200) {
          setSavedContent([...currentSavedContent]);
          setUserMetricsData(metricData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const shareHandler = (title) => {
    setShareContentTitle(title);
    setShareContent(true);
  };

  const getContentLinkHandler = (item) => {
    call(`content/${item.title}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setPlayContent(true);
          setContent(res.data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log("error is", err);
        Sentry.captureException(err);
      });
  };

  const closeContentPlayer = () => {
    setPlayContent(false);
  };

  const closeShareContentHandler = () => {
    setShareContent(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={t("pageTitles.contentLibrary")} />
      <UserSelection
        learnersDetails={learnersDetails}
        defaultLearner={defaultLearner}
        learnerChangeHandler={learnerChangeHandler}
        allAssessments={allAssessments}
        val={val}
        setVal={setVal}
        setAssessmentId={setAssessmentId}
        t={t}
      />
      {insight && (
        <Grid container spacing={1} mt={2}>
          <TopContent
            topContent={topContent}
            profile={profile}
            getContentLinkHandler={getContentLinkHandler}
            likedContent={likedContent}
            dislikedContent={dislikedContent}
            likeHandler={likeHandler}
            dislikeHandler={dislikeHandler}
            removeLikeHandler={removeLikeHandler}
            removeDislikeHandler={removeDislikeHandler}
            shareHandler={shareHandler}
            saveHandler={saveHandler}
            savedContent={savedContent}
            viewedContent={viewedContent}
            learnerId={learnerId}
            t={t}
          />
          <Grid item xs={12} md={12} flex={1}>
            <Card style={{ height: "100%" }}>
              <MDBox
                style={{ height: "100%" }}
                bgColor="white"
                variant="gradient"
                borderRadius="xl"
                sx={{ background: ({ palette: { background } }) => false && background.card }}
              >
                <MDBox py={2} pr={2} pl={4} style={{ height: "100%" }}>
                  <MDBox pt={2} px={2} lineHeight={1.25}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {t("contentLibrary.contentTitle",{"name":learnerName})}
                      {/* {learnerName}`s Content */}
                    </MDTypography>
                  </MDBox>
                  {assessmentType == "NEO" && (
                    <>
                      <MDBox pt={2} px={6} lineHeight={1.25}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {t("contentLibrary.filterBy")}
                        </MDTypography>
                      </MDBox>
                      <MDBox py={2} px={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={4} lg={2}>
                            <MDButton
                              variant={
                                contentFilter == "allContentLinks" ? "contained" : "outlined"
                              }
                              color="primary"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setInsightContentLinks(allContentLinks);
                                setContentFilter("allContentLinks");
                              }}
                            >
                              {t("contentLibrary.showAll")}
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <MDButton
                              variant={contentFilter == "Agreeableness" ? "contained" : "outlined"}
                              color="primary"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setInsightContentLinks(
                                  insight.Agreeableness.content_links.sort((a, b) =>
                                    parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
                                  )
                                );
                                setContentFilter("Agreeableness");
                              }}
                            >
                              {t("contentLibrary.agreeableness")}
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <MDButton
                              variant={
                                contentFilter == "Conscientiousness" ? "contained" : "outlined"
                              }
                              color="primary"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setInsightContentLinks(
                                  insight.Conscientiousness.content_links.sort((a, b) =>
                                    parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
                                  )
                                );
                                setContentFilter("Conscientiousness");
                              }}
                            >
                             {t("contentLibrary.conscientiousness")} 
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <MDButton
                              variant={contentFilter == "Openness" ? "contained" : "outlined"}
                              color="primary"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setInsightContentLinks(
                                  insight.Openness.content_links.sort((a, b) =>
                                    parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
                                  )
                                );
                                setContentFilter("Openness");
                              }}
                            >
                              {t("contentLibrary.openness")} 
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <MDButton
                              variant={contentFilter == "Extraversion" ? "contained" : "outlined"}
                              color="primary"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setInsightContentLinks(
                                  insight.Extraversion.content_links.sort((a, b) =>
                                    parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
                                  )
                                );
                                setContentFilter("Extraversion");
                              }}
                            >
                              {t("contentLibrary.extraversion")} 
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <MDButton
                              variant={contentFilter == "Temperament" ? "contained" : "outlined"}
                              color="primary"
                              style={{ width: "100%" }}
                              onClick={() => {
                                setInsightContentLinks(
                                  insight.Temperament.content_links.sort((a, b) =>
                                    parseFloat(a.weight) < parseFloat(b.weight) ? 1 : -1
                                  )
                                );
                                setContentFilter("Temperament");
                              }}
                            >
                              {t("contentLibrary.temperament")} 
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </>
                  )}
                  <UserContent
                    inSightContentLinks={inSightContentLinks}
                    profile={profile}
                    getContentLinkHandler={getContentLinkHandler}
                    likedContent={likedContent}
                    dislikedContent={dislikedContent}
                    likeHandler={likeHandler}
                    dislikeHandler={dislikeHandler}
                    removeLikeHandler={removeLikeHandler}
                    removeDislikeHandler={removeDislikeHandler}
                    shareHandler={shareHandler}
                    saveHandler={saveHandler}
                    savedContent={savedContent}
                    viewedContent={viewedContent}
                    learnerId={learnerId}
                    t={t}
                  />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      )}

      {noContent && (
        <MDBox mt={2.5}>
          <Card style={{ backgroundColor: "#49a3f1", padding: 20, opacity: 0.8 }}>
            <MDBox display="flex" flexDirection="column">
              <MDTypography
                textAlign="center"
                justifyContent="center"
                component="h3"
                fontWeight="medium"
                color="white"
                flexWrap="wrap"
              >
                {t("contentLibrary.noContentTitle")}
              </MDTypography>
              <MDTypography
                textAlign="center"
                component="h3"
                variant="body2"
                fontWeight="medium"
                color="white"
                alignItems="center"
                flexWrap="wrap"
              >
                 {t("contentLibrary.noContentMessage")}
              </MDTypography>
              <MDTypography
                textAlign="center"
                component="h3"
                variant="body2"
                fontWeight="medium"
                color="white"
                alignItems="center"
                flexWrap="wrap"
              >
                 {t("contentLibrary.noContentSupport", { email: "support@maxu.co" })}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      )}

      {loading && (
        <Dialog open={loading} fullScreen>
          <DialogContent>
            <Loader loaderText={t("loaderText.loadingContent")} />
          </DialogContent>
        </Dialog>
      )}

      {playContent && (
        <ContentPlayerDialog
          playContent={playContent}
          closeContentPlayer={closeContentPlayer}
          content={content}
          userMetricsData={userMetricsData}
          setViewedContent={setViewedContent}
          setUserMetricData={setUserMetricsData}
          userId={profile.id}
          setSessionExpired={setSessionExpired}
          t={t}
        />
      )}

      {shareContent && (
        <ShareContentDialog
          shareContent={shareContent}
          setShareContent={setShareContent}
          closeShareContentHandler={closeShareContentHandler}
          shareContentTitle={shareContentTitle}
          learnerMetricsData={learnerMetricsData}
          setLearnerMetricData={setLearnerMetricData}
          learnerId={learnerId}
          setSessionExpired={setSessionExpired}
          t={t}
        />
      )}
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

export default ContentLibrary;

// function Transaction({ index, message }) {
//   return (
//     <MDBox key={index} component="ul" py={1} pr={2} mb={1}>
//       <MDBox display="flex" justifyContent="space-between" alignItems="center">
//         <MDBox display="flex" alignItems="center">
//           <MDBox
//             display="flex"
//             flexDirection="column"
//             component="ul"
//             style={{ listStyleType: "disc" }}
//           >
//             <MDTypography
//               component="li"
//               variant="caption"
//               color="text"
//               fontSize={20}
//               fontWeight={"light"}
//             >
//               {message}
//             </MDTypography>
//           </MDBox>
//         </MDBox>
//       </MDBox>
//     </MDBox>
//   );
// }

const ContentPlayerDialog = ({
  playContent,
  closeContentPlayer,
  content,
  userMetricsData,
  setViewedContent,
  setUserMetricData,
  userId,
  setSessionExpired,
  t
}) => {
  let src = content.content_url;
  let type = content.content_type.toLowerCase();
  let title = content.title;
  useEffect(() => {
    if (type == "video") {
      updateMetrics();
    }
  }, []);

  const updateMetrics = () => {
    let viewedcontent = userMetricsData["viewedcontent"];
    if (viewedcontent.includes(title)) {
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    } else {
      userMetricsData["viewedcontent"] = [...viewedcontent, title];
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    }
    call(`users/${userId}/contentmetrics`, "PUT", { body: userMetricsData })
      .then((res) => {
        if (res.status == 200) {
          setUserMetricData(userMetricsData);
          setViewedContent([...userMetricsData.viewedcontent]);
        } else if (res.status == 440) {
          setSessionExpired(true);
          closeContentPlayer();
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };
  return (
    <Dialog open={playContent} onClose={closeContentPlayer} fullWidth={true} maxWidth={"md"}>
      <CancelPresentationIcon
        fontSize="large"
        onClick={closeContentPlayer}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        {type == "video" ? (
          <ReactPlayer url={src} playing={true} controls={true} width="100%" />
        ) : (
          <MDBox
            display="flex"
            justifyContent="center"
            alignSelf="end"
            alignItems="center"
            onClick={closeContentPlayer}
          >
            <MDButton
              onClick={updateMetrics}
              component={"a"}
              href={src}
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              size="large"
              color={"info"}
            >
              {t("playContent.openInNewTab")}
            </MDButton>
          </MDBox>
        )}
      </DialogContent>
    </Dialog>
  );
};

const ShareContentDialog = ({
  shareContent,
  setShareContent,
  closeShareContentHandler,
  shareContentTitle,
  learnerMetricsData,
  setLearnerMetricData,
  learnerId,
  setSessionExpired,
  t
}) => {
  const [learnersRecommendations, setLearnerRecommendations] = useState(
    learnerMetricsData["recommendedcontent"]
  );
  const [recommend, setRecommend] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (learnersRecommendations.includes(shareContentTitle)) {
      setRecommend(false);
    }
  }, []);

  const recommendHandler = () => {
    setLoading(true);
    learnerMetricsData["recommendedcontent"] = [...learnersRecommendations, shareContentTitle];
    learnerMetricsData["eventkey"] = { event: "recommendcontent", value: shareContentTitle };
    call(`users/${learnerId}/contentmetrics`, "PUT", { body: learnerMetricsData })
      .then((res) => {
        if (res.status == 200) {
          setLearnerMetricData(learnerMetricsData);
          setShareContent(false);
        } else if (res.status == 440) {
          setSessionExpired(true);
          setShareContent(false);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setLoading(false);
      });
  };

  return (
    <Dialog open={shareContent} onClose={closeShareContentHandler} fullWidth={true} maxWidth={"md"}>
      <CancelPresentationIcon
        fontSize="large"
        onClick={closeShareContentHandler}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />

      <DialogTitle>{t("shareContent.title")}</DialogTitle>
      {recommend ? (
        <DialogContent>
          <MDTypography variant="h6" fontWeight="high">
            {t("shareContent.recommend", {title:shareContentTitle})}
          </MDTypography>
        </DialogContent>
      ) : (
        <DialogContent>
          <MDTypography variant="h6" fontWeight="high">
          {t("shareContent.shared")}
          </MDTypography>
        </DialogContent>
      )}

      <DialogActions>
        {recommend && (
          <MDButton onClick={recommendHandler} style={{ color: "white", background: "#0AB2D6" }}>
            {loading ? <CircularProgress size={15} color={"white"} /> : t("shareContent.buttonTitles.recommend")}
          </MDButton>
        )}

        <MDButton
          onClick={closeShareContentHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          {t("shareContent.buttonTitles.close")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

const UserSelection = ({
  learnersDetails,
  defaultLearner,
  learnerChangeHandler,
  allAssessments,
  val,
  setVal,
  setAssessmentId,
  t
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <>
      {learnersDetails.length > 0 && (
        <Card>
          <MDBox display="flex" alignItems="center" justifyContent="space-between" p={2} m={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                {learnersDetails.length > 0 && (
                  <MDBox>
                    <MDBox style={{ marginBottom: "10px", marginLeft: "2px" }}>
                      <FormLabel sx={{ color: "#49A3F1", marginBottom: "20px", fontSize: "20px" }}>
                        {t("contentLibrary.selectLearner")}:
                      </FormLabel>
                    </MDBox>
                    <Autocomplete
                      disableClearable
                      defaultValue={defaultLearner}
                      id="checkboxes-tags-demo"
                      options={learnersDetails}
                      onChange={(event, newValue) => {
                        learnerChangeHandler(newValue);
                      }}
                      getOptionLabel={(option) => option.learnerName}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.learnerName}
                        </li>
                      )}
                      fullWidth
                      style={{ maxWidth: 400 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ boxShadow: "1px 2px 9px #49a3f1" }}
                          placeholder={t("contentLibrary.selectLearner")}
                        />
                      )}
                    />
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                {allAssessments.length > 0 && (
                  <MDBox>
                    <MDBox style={{ marginBottom: "10px", marginLeft: "2px" }}>
                      <FormLabel sx={{ color: "#49A3F1", marginBottom: "20px", fontSize: "20px" }}>
                      {t("contentLibrary.selectAssessment")}:
                      </FormLabel>
                    </MDBox>
                    <Autocomplete
                      value={val}
                      disableClearable
                      id="checkboxes-tags-assessments"
                      options={allAssessments}
                      onChange={(event, newValue) => {
                        setVal(newValue);
                        setAssessmentId(newValue["assessment_id"]);
                      }}
                      getOptionLabel={(option) =>
                        option.assessment_name + " " + option.completion_date
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.assessment_name + " " + option.completion_date}
                        </li>
                      )}
                      fullWidth
                      style={{ maxWidth: 400 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ boxShadow: "1px 2px 9px #49a3f1" }}
                          placeholder= {t("contentLibrary.selectAssessment")}
                        />
                      )}
                    />
                  </MDBox>
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      )}
    </>
  );
};

const TopContent = ({
  topContent,
  profile,
  getContentLinkHandler,
  likedContent,
  dislikedContent,
  likeHandler,
  dislikeHandler,
  removeLikeHandler,
  removeDislikeHandler,
  shareHandler,
  saveHandler,
  savedContent,
  viewedContent,
  learnerId,
  t
}) => {
  return (
    <>
      {topContent && (
        <Grid item xs={12} md={12} flex={1}>
          <Card style={{ height: "100%" }}>
            <MDBox
              style={{ height: "100%" }}
              bgColor="white"
              variant="gradient"
              borderRadius="xl"
              sx={{ background: ({ palette: { background } }) => false && background.card }}
            >
              <MDBox py={2} pr={2} pl={4} style={{ height: "100%" }}>
                <MDBox pt={2} px={2} lineHeight={1.25}>
                  <MDTypography variant="h4" fontWeight="medium">
                   {t("contentLibrary.topContentTitle")}
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <Grid container spacing={6}>
                    {topContent &&
                      topContent.map((item, index) => {
                        let icon = "movie";
                        let content_type = item.content_type.toLowerCase();
                        if (content_type == "video") {
                          icon = "movie";
                        } else if (content_type == "infographic") {
                          icon = "leaderboard";
                        } else if (content_type == "article") {
                          icon = "article";
                        } else if (content_type == "worksheet") {
                          icon = "note";
                        }
                        return (
                          <Grid item xs={12} md={4}>
                            <DefaultProjectCard
                              item={item}
                              icon={icon}
                              type={item.content_type}
                              title={item.title}
                              shareable={learnerId != profile.id}
                              content_url={item.content_url}
                              image={item.thumbnail_url || MaxuLogo}
                              getContentLinkHandler={getContentLinkHandler}
                              liked={likedContent.includes(item.title)}
                              disliked={dislikedContent.includes(item.title)}
                              saved={savedContent.includes(item.title)}
                              viewed={viewedContent.includes(item.title)}
                              likeHandler={likeHandler}
                              dislikeHandler={dislikeHandler}
                              removeLikeHandler={removeLikeHandler}
                              removeDislikeHandler={removeDislikeHandler}
                              saveHandler={saveHandler}
                              shareHandler={shareHandler}
                              action={{
                                type: "external",
                                route: item.content_url,
                                color: "info",
                                label: t("buttonTitles.view"),
                              }}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      )}
    </>
  );
};

const UserContent = ({
  inSightContentLinks,
  profile,
  getContentLinkHandler,
  likedContent,
  dislikedContent,
  likeHandler,
  dislikeHandler,
  removeLikeHandler,
  removeDislikeHandler,
  shareHandler,
  saveHandler,
  savedContent,
  viewedContent,
  learnerId,
  t
}) => {
  return (
    <>
      {inSightContentLinks && (
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={12} flex={1}>
            <MDBox
              style={{ height: "100%" }}
              bgColor="white"
              variant="gradient"
              borderRadius="xl"
              sx={{
                background: ({ palette: { background } }) => false && background.card,
              }}
            >
              <MDBox py={2} style={{ height: "100%" }}>
                <MDBox py={2}>
                  <Grid container spacing={6}>
                    {inSightContentLinks &&
                      inSightContentLinks.map((item, index) => {
                        let icon = "movie";
                        let content_type = item.content_type.toLowerCase();
                        if (content_type == "video") {
                          icon = "movie";
                        } else if (content_type == "infographic") {
                          icon = "leaderboard";
                        } else if (content_type == "article") {
                          icon = "article";
                        } else if (content_type == "worksheet") {
                          icon = "note";
                        }
                        return (
                          <Grid item xs={12} md={4}>
                            <DefaultProjectCard
                              item={item}
                              icon={icon}
                              type={item.content_type}
                              title={item.title}
                              shareable={learnerId != profile.id}
                              content_url={item.content_url}
                              image={item.thumbnail_url || MaxuLogo}
                              getContentLinkHandler={getContentLinkHandler}
                              liked={likedContent.includes(item.title)}
                              disliked={dislikedContent.includes(item.title)}
                              saved={savedContent.includes(item.title)}
                              viewed={viewedContent.includes(item.title)}
                              likeHandler={likeHandler}
                              dislikeHandler={dislikeHandler}
                              removeLikeHandler={removeLikeHandler}
                              removeDislikeHandler={removeDislikeHandler}
                              saveHandler={saveHandler}
                              shareHandler={shareHandler}
                              action={{
                                type: "external",
                                route: item.content_url,
                                color: "info",
                                label: t("buttonTitles.view"),
                              }}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </>
  );
};
