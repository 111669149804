import axios from "axios";
import { BASE_URL } from "./config";

export const call = (path: string, method: string, request: Request) => {
  return callRaw(path, method, request)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      console.log("API ERROR RESPONSE IS", error);
      return error.response;
    });
};

export const callRaw = (path: string, method: string, request: Request) => {
  let session = JSON.parse(localStorage.getItem("session"));
  const localLang = localStorage.getItem("i18nextLng") || "en";
  let localLangCode = localLang.split("-")[0];
  const supportedLanguages = ["en", "es", "it", "pt", "fr", "de"];
  if (!supportedLanguages.includes(localLangCode)) {
    localLangCode = "en";
  }
  return axios({
    method,
    url: `${BASE_URL}${path}`,
    headers: {
      Accept: "application/json",
      "i18n-Language": localLangCode,
      AccessControlAllowOrigin: "*",
      AccessControlAllowMethods: "PUT,GET,POST,DELETE,OPTIONS",
      Authorization: "Bearer " + session.jwtToken,
      ...request.headers,
    },
    data: request.body,
    params: request.params,
    responseType: request.responseType,
  });
};
