import React, { useRef, useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { call } from "services/axiosService/core";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import Loader from "pages/loader/Loader";
import {
  Checkbox,
  FormLabel,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
} from "@mui/material";

const ManageSubscription = ({
  openSubscriptionDialog,
  setOpenSubscriptionDialog,
  setOpenSnackbar,
  setSeverity,
  setAlertMessage,
  setSessionExpired
}) => {
  const [controller] = useMaterialUIController();
  const { persona, beta, profile } = controller;
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [preferences, setPreferences] = useState({});
  const [preferencesLoaded, setPreferencesLoaded] = useState(false)

   // Set categories based on persona
   useEffect(() => {
    switch (persona) {
      case "athlete":
        setCategories(["login_reminder", "assessment_reminder", "maxwell_reminder"])
        break
      case "guardian":
        setCategories(["login_reminder", "child/athlete_signup_reminder"])
        break
      case "adultAthlete":
        setCategories(["login_reminder", "assessment_reminder", "maxwell_reminder", "child/athlete_signup_reminder"])
        break
      case "admin":
        setCategories(["login_reminder"])
        break
      case "contentManager":
        setCategories(["login_reminder"])
        break
      default:
        setCategories(["login_reminder"])
        break
    }
  }, []);

  // Fetch email preferences when categories change
  useEffect(() => {
    if (categories.length > 0) {
      getEmailPreferences();
    }
  }, [categories]);

  const getEmailPreferences = () => {
    call(`users/${profile.id}/emailpreferences`, "GET", {})
      .then((response) => {
        if (response.status === 200) {
          const pref = response["data"]["email_preferences"] || {};
          const initializedPreferences = pref;
          categories.forEach((category) => {
            initializedPreferences[category] = pref[category] ?? true; // Default to true
          });
          setPreferences(initializedPreferences);
          setPreferencesLoaded(true)
        } else if (response.status === 440) {
          setSessionExpired(true);
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            endpoint: `users/${profile.id}/emailpreferences`,
            method: "GET",
          },
        });
        console.error("Error fetching email preferences:", error);
      });
  };

  const handleToggle = (category) => {
    setPreferences((prev) => ({
      ...prev,
      [category]: !prev[category], // Toggle the value
    }));
  };


  const handleSave = () => {
    call(`users/${profile.id}/emailpreferences`, "PUT",  { body: preferences })
      .then((response) => {
        if (response.status === 200) {
          setOpenSnackbar(true);
          setSeverity("success");
          setAlertMessage(t("alerts.profile.preferencesUpdated"));
          setOpenSubscriptionDialog(false);
        }
        else if (response.status === 440) {
          setSessionExpired(true);
        }
        else{
          setOpenSnackbar(true);
          setSeverity("success");
          setAlertMessage(t("alerts.profile.errorUpdatingPreferences"));
        }

      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            endpoint: `users/${profile.id}/emailpreferences`,
            method: "PUT",
            data: { preferences },
          },
        });
        console.error("Error saving email preferences:", error);
        setOpenSnackbar(true);
        setSeverity("error");
        setAlertMessage(t("alerts.profile.errorUpdatingPreferences"));
      });
  };
  
  return (
    preferencesLoaded && (
      <Dialog
      open={openSubscriptionDialog}
      onClose={() => {
        setOpenSubscriptionDialog(false);
      }}
    >
      <CloseIcon
        fontSize="medium"
        onClick={() => {
          setOpenSubscriptionDialog(false);
        }}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />

      <DialogTitle>
        <MDTypography variant="h4"> {t("headings.manageSubscription")}</MDTypography>
        <MDTypography variant="subtitle2" sx={{ fontSize: 13 }}>
          {t('messages.profile.manageSubscription')}
        </MDTypography>
      </DialogTitle>
      <DialogContent>
      <List>
          {categories.map((category) => (
            <ListItem key={category}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preferences[category] ?? true} // Default to true
                    onChange={() => handleToggle(category)}
                  />
                }
                label = {t(`emailPreferences.${category}`)}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          style={{
            marginTop: "5px",
            color: "white",
            background: "#0AB2D6",
          }}
        >
          {t("buttonTitles.save")}
        </Button>
      </DialogActions>
    </Dialog>
    )
  );
};

export default ManageSubscription;
