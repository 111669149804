/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { useMediaQuery } from "@mui/material";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import { useTranslation } from "react-i18next";

function DefaultProjectCard({
  item,
  image,
  liked,
  disliked,
  saved,
  viewed,
  getContentLinkHandler,
  likeHandler,
  dislikeHandler,
  removeLikeHandler,
  removeDislikeHandler,
  saveHandler,
  shareHandler,
  editable,
  editContentHandler,
  type,
  label,
  title,
  shareable,
  description,
  content_url,
  thumbnail_url,
  action,
  authors,
  icon,
}) {
  // const [like,  setLike] = useState(liked)
  // const [dislike, setDislike] = useState(disliked)
  const {t} = useTranslation();
  const [save, setSave] = useState(saved);
  const matches = useMediaQuery("(max-width:920px)");
  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <MDAvatar
        src={media}
        alt={name}
        size="xs"
        sx={({ borders: { borderWidth }, palette: { white } }) => ({
          border: `${borderWidth[2]} solid ${white.main}`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,

          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "1px 2px 9px #49a3f1",
        padding: "0.25em",
        overflow: "visible",
        height: "100%",
        border: "1px solid #49a3f1",
        transition: "background-color 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          backgroundColor: "#f0f8ff", 
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", 
        },
      }}
    >
      <MDBox display="flex" justifyContent="space-between" position="relative" width="100.25%">
        {icon && <Icon fontSize="large">{icon}</Icon>}
        {shareable && (
          <Tooltip title={t("contentCard.recommend")}>
            <Icon
              onClick={() => {
                shareHandler(title);
              }}
              fontSize="large"
            >
              send
            </Icon>
          </Tooltip>
        )}
        {viewed && (
          <Tooltip title={t("contentCard.viewed")}>
            <Icon fontSize="large">visibility</Icon>
          </Tooltip>
        )}
        {saved ? (
          <Tooltip title={t("contentCard.removeFromWatchlist")}>
            <Icon
              onClick={() => {
                // setSave(!save);
                saveHandler(title);
              }}
              fontSize="large"
            >
              star
            </Icon>
          </Tooltip>
        ) : (
          <Tooltip title= {t("contentCard.saveToWatchlist")}>
            <Icon
              onClick={() => {
                // setSave(!save);
                saveHandler(title);
              }}
              fontSize="large"
            >
              star_border
            </Icon>
          </Tooltip>
        )}
      </MDBox>

      {image && (
        <MDBox position="relative" flex={1} width="100.25%" shadow="xl" borderRadius="xl">
          <CardMedia
            src={image}
            component="img"
            title={title}
            height="100%"
            // width="400"
            sx={{
              maxWidth: "100%",
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </MDBox>
      )}
      <MDBox mt={1} mx={0.5}>
        <MDBox
          mb={3}
          mt={1}
          lineHeight={0}
          flex={1}
          overflow={`${title.length > 80 && `hidden`}`}
          maxHeight={`${matches ? `60px` : `25px`}`}
          sx={{ textAlign: "center", textOverflow: "ellipsis" }}
        >
          <MDTypography variant="button" fontWeight="medium" color="text">
            {title}
          </MDTypography>
        </MDBox>

        {/* <MDBox mb={2} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </MDBox> */}

        {/* <MDBox mb={2} lineHeight={0} >
          <a href={action.route} target="_blank" >
            <video
              style={{ width: "100%", height: "auto" }}
              src={action.route}>
            </video>
          </a>
        </MDBox> */}

        <MDBox
          display="flex"
          overflow="auto"
          justifyContent="space-between"
          alignSelf="end"
          alignItems="center"
        >
          {action.type === "internal" ? (
            <MDButton
              component={Link}
              to={action.route}
              variant="outlined"
              size="medium"
              color={action.color}
            >
              {action.label}
            </MDButton>
          ) : (
            <MDButton
              component={"button"}
              onClick={() => getContentLinkHandler(item)}
              // href={action.route}
              // target="_blank"
              // rel="noreferrer"
              variant="outlined"
              size="medium"
              color={action.color}
            >
              {action.label}
            </MDButton>
          )}
          <Tooltip title={liked ? t("contentCard.removeLike") : t("contentCard.like")}>
            <MDButton
              onClick={() => {
                //  setLike(!like)
                liked ? removeLikeHandler(title) : likeHandler(title);
              }}
              component={"button"}
              variant="outlined"
              size="medium"
              color={action.color}
            >
              {liked ? (
                <ThumbUpIcon sx={{ fontSize: 40 }} />
              ) : (
                <ThumbUpOutlinedIcon sx={{ fontSize: 40 }} />
              )}
            </MDButton>
          </Tooltip>
          <Tooltip title={disliked ?  t("contentCard.removeDislike") : t("contentCard.dislike")}>
            <MDButton
              onClick={() => {
                // setDislike(!dislike)
                disliked ? removeDislikeHandler(title) : dislikeHandler(title);
              }}
              component={"button"}
              variant="outlined"
              size="medium"
              color={action.color}
            >
              {disliked ? (
                <ThumbDownIcon sx={{ fontSize: 40 }} />
              ) : (
                <ThumbDownOutlinedIcon sx={{ fontSize: 40 }} />
              )}
            </MDButton>
          </Tooltip>

          {/* <MDBox display="flex">{renderAuthors}</MDBox> */}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  item: {},
  editable: false,
  shareable: true,
  liked: false,
  disliked: false,
  saved: false,
  viewed: false,
  likeHandler: () => {},
  dislikeHandler: () => {},
  removeLikeHandler: () => {},
  removeDislikeHandler: () => {},
  saveHandler: () => {},
  shareHandler: () => {},
  authors: [],
  getContentLinkHandler: () => {},
  editContentHandler: () => {},
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  item: PropTypes.object,
  icon: PropTypes.string,
  image: PropTypes.string,
  liked: PropTypes.bool,
  disliked: PropTypes.bool,
  saved: PropTypes.bool,
  viewed: PropTypes.bool,
  likeHandler: PropTypes.func,
  dislikeHandler: PropTypes.func,
  removeLikeHandler: PropTypes.func,
  removeDislikeHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  shareHandler: PropTypes.func,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  shareable: PropTypes.bool,
  description: PropTypes.string,
  getContentLinkHandler: PropTypes.func,
  editContentHandler: PropTypes.func,
  editable: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.instanceOf(Array),
};

export default DefaultProjectCard;
