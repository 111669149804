import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

import { useNavigate, useLocation } from "react-router-dom";
import AthleteOnBoardingScreen from "./AthleteOnBoardingScreen";

import React, { useEffect, useState } from "react";
import { call } from "services/axiosService/core";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useMaterialUIController } from "context";
import Loader from "pages/loader/Loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import Refresh from "pages/refresh/Refresh";
import MuiAlert from "@mui/material/Alert";
import * as Sentry from "@sentry/browser";
import Bot from "pages/bot/Bot";
import { useTranslation, Trans } from "react-i18next";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ViewInsightsButton = ({ id, user_id, t }) => {
  const navigate = useNavigate();
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>{t("toolTips.viewInsights")}</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 3 }}
      >
        <Icon
          fontSize="large"
          color="inherit"
          onClick={() => navigate(`/insight/${id}/${user_id}`)}
        >
          psychology
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const TakeAssessmentButton = ({ id, t}) => {
  const navigate = useNavigate();
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>{t("toolTips.takeAssessment")}</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 3 }}
      >
        <Icon fontSize="large" color="inherit" onClick={() => navigate(`/assessment/${id}`)}>
          description
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const ResumeAssessmentButton = ({ id,t }) => {
  const navigate = useNavigate();
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>{t("toolTips.resumeAssessment")}</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 3 }}
      >
        <Icon fontSize="large" color="inherit" onClick={() => navigate(`/assessment/${id}`)}>
          description
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function AthleteDashboard() {
  const { t } = useTranslation();
  const location = useLocation();
  const openBot = location.state?.openBot || false;
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [assessments, setAssessments] = useState([]);
  const [hadAssessment, setHadAssessment] = useState(false);
  const [resumeAssessment, setResumeAssessment] = useState(null);
  const [controller] = useMaterialUIController();
  const [openLoader, setOpenLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);
  const { profile, persona } = controller;

  const [showBot, setShowBot] = useState(false);
  const [autoExpandBot, setAutoExpandBot] = useState(openBot)
  const [showUnreadChat, setShowUnreadChat] = useState(false);
  const [showTodayContent, setShowTodayContent] = useState(false);
  const [todayContent, setTodayContent] = useState([]);
  const [chatQuestions, setChatQuestions] = useState([]);
  const [showIntro, setShowIntro] = useState(false);
  const [userMetricsData, setUserMetricsData] = useState(null);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const tableData = {
    columns: [
      { Header: t("tableHeaders.name"), accessor: "name" },
      { Header: t("tableHeaders.assignedDate"), accessor: "assigned_date" },
      { Header: t("tableHeaders.status"), accessor: "status" },
      {
        Header: t("tableHeaders.actions"),
        accessor: "actions",
        width: "15%",
      },
    ],

    rows: assessments,
  };

  tableData.rows = tableData.rows.map((row) => {
    let status;
    let id = row.id;
    let user_id = row.user_id;
    let action;
    if (row.status == "ready") {
      status = t(`userDynamicData.completed`,{date:row.completion_date})
      // status = `completed-${row.completion_date}`;
    } else {
      status = t(`userDynamicData.${row.status}`)
      // status = row.status;
    }
    switch (status) {
      case t("userDynamicData.not_started"):
        action = <TakeAssessmentButton id={id} t={t}/>;
        break;
      case t("userDynamicData.in_progress"):
        action = <ResumeAssessmentButton id={id} t={t}/>;
        break;
      case t("userDynamicData.scoring"):
        action = "";
        break;
      case t("userDynamicData.submitted"):
        action = "";
        break;
      case t(`userDynamicData.completed`,{date:row.completion_date}):
        action = <ViewInsightsButton id={id} user_id={user_id} t={t}/>;
        break;
      default:
        action = "";
    }

    return {
      id: row.id,
      actions: action,
      name: row.details.name,
      assigned_date: row.assigned_date,
      status: status,
    };
  });

  useEffect(() => {
    if (persona == "athlete") {
      loadDailyChatQuestions();
      getUnreadStatus();
      getDailyContent();
      getIntroMessageStatus();
      loadUserContentMetrics();
    }
  }, [persona]);

  const loadUserContentMetrics = () => {
    call(`users/${profile.id}/contentmetrics`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setUserMetricsData(data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
  };

  const loadDailyChatQuestions = () => {
    call(`chats/bot`, "GET", {})
      .then((res) => {
        if (res.status == 404) {
          setChatQuestions([]);
        } else if (res.status == 200) {
          let questions = res.data;
          const updatedQuestions = questions.map((item) => {
            return { ...item, userResponse: "" };
          });
          setChatQuestions([...updatedQuestions]);
        } else if (res.status == 440) {
          setChatQuestions([]);
          //   setLoadingUser(false);
          //   setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const getUnreadStatus = () => {
    let currentDate = new Date().toLocaleDateString();
    let lastBotViewed = localStorage.getItem("lastBotViewed") || null;
    if (currentDate != lastBotViewed) {
      setShowUnreadChat(true);
    }
  };

  const getIntroMessageStatus = () => {
    let currentDate = new Date().toLocaleDateString();
    let lastIntroViewed = localStorage.getItem("IntroMessageViewed") || null;
    if (currentDate != lastIntroViewed) {
      setShowIntro(true);
    }
  };

  const getDailyContent = () => {
    let currentDate = new Date().toLocaleDateString();
    let lastContentUpdated = localStorage.getItem("lastContentUpdatedDate") || null;
    if (currentDate == lastContentUpdated) {
      let dailyContent = JSON.parse(localStorage.getItem("dailyContent")) || null;
      if(dailyContent){
        setShowTodayContent(true);
        setTodayContent(dailyContent);
      }
    }
  };

  useEffect(() => {
    if (profile.status == "active") {
      loadingAssessmentsHandler();
    }
  }, []);

  const loadingAssessmentsHandler = () => {
    if (profile.id) {
      call(`users/${profile.id}/assessments`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            setAssessments(res.data.Assessments);
            res.data.Assessments.map((item, index) => {
              if (
                item.status == "not_started" ||
                item.status == "in_progress" ||
                item.status == "not started"
              ) {
                setHadAssessment(true);
                setResumeAssessment(item);
                // localStorage.setItem("ActiveAssessment", JSON.stringify(item));
              } else {
                setShowBot(true);
              }
            });
          } else if (res.status == 404) {
            setAssessments([]);
            setOpenSnackbar(true);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Sentry.captureException(err);
        });
    }
  };

  return (
    <DashboardLayout>
      <AthleteOnBoardingScreen
        role="athlete"
        setOpenLoader={setOpenLoader}
        setSessionExpired={setSessionExpired}
      />
      <DashboardNavbar title={t("pageTitles.assessmentDashboard")} />
      {openLoader && (
        <LoadingAssessmentDialog
          openLoader={openLoader}
          setOpenLoader={setOpenLoader}
          loadingAssessmentsHandler={loadingAssessmentsHandler}
          t={t}
        />
      )}

      {hadAssessment && (
        <MDBox mt={2.5}>
          <MDAlert color="info">
            <MDTypography variant="body2" color="white" fontWeight="medium">
              {t("notifications.newAssessment.welcome", {
                name: profile.details.name.split(" ")[0],
              })}
              <MDTypography
                component="a"
                href={`/assessment/${resumeAssessment.id}`}
                variant="body2"
                fontWeight="medium"
                color="dark"
              >
                {t("notifications.newAssessment.assessment")}
              </MDTypography>{" "}
              {t("notifications.newAssessment.assigned")}
              <br />
              {t("notifications.newAssessment.instruction")}{" "}
              <MDTypography
                component="a"
                href={`/assessment/${resumeAssessment.id}`}
                variant="body2"
                fontWeight="medium"
                color="dark"
              >
                {t("notifications.newAssessment.clickHere")}
              </MDTypography>{" "}
              {t("notifications.newAssessment.instructionContinuation")}
            </MDTypography>
          </MDAlert>
        </MDBox>
      )}

      <MDBox mt={6}>
        <MDBox mt={3} mb={3}>
          <Card>
            <MDBox p={2} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {t("headings.assessments")}
              </MDTypography>
            </MDBox>
            <DataTable
              entriesPerPage={false}
              table={tableData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </Card>
        </MDBox>
      </MDBox>

      <Snackbar
        autoHideDuration={10000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: "100%" }}>
        {t("notifications.noAssessments.title")}{" "}
  {t("notifications.noAssessments.support")}{" "}
  <a href="mailto:support@maxu.co" >
    support@maxu.co
  </a>{" "}
  {t("notifications.noAssessments.continue")}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}

      {showBot && chatQuestions.length > 0 && (
        <Bot
          showUnreadChat={showUnreadChat}
          setShowUnreadChat={setShowUnreadChat}
          showTodayContent={showTodayContent}
          todayContent={todayContent}
          chatQuestions={chatQuestions}
          showIntro={showIntro}
          userMetricsData={userMetricsData}
          setUserMetricsData={setUserMetricsData}
          setSessionExpired={setSessionExpired}
          autoExpandBot= {autoExpandBot}
          profile={profile}
        />
      )}
    </DashboardLayout>
  );
}

const LoadingAssessmentDialog = ({ setOpenLoader, openLoader, loadingAssessmentsHandler, t }) => {
  useEffect(() => {
    setTimeout(() => {
      loadingAssessmentsHandler();
      setOpenLoader(false);
    }, 10000);
  }, []);

  return (
    <Dialog open={openLoader} fullScreen>
      <DialogContent>
        <Loader loaderText={t("loaderText.preparingAssessment")} />
      </DialogContent>
    </Dialog>
  );
};
